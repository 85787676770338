<template>
  <div>
    <b-form-group label="Nome:">
      <b-form-input
        v-model="user.name"
        placeholder="Nome"
        :state="validateState('name')"
      ></b-form-input>
      <b-form-invalid-feedback
        >Nome é um campo obrigatório.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group label="E-mail:">
      <b-form-input
        v-model="user.email"
        placeholder="E-mail"
        disabled
        :state="validateState('email')"
      ></b-form-input>
      <b-form-invalid-feedback>
        <span v-if="!$v.user.email.required"
          >E-mail é um campo obrigatório.</span
        >
        <span v-if="!$v.user.email.email">E-mail inválido</span>
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group label="Senha:">
      <b-form-input
        :type="'password'"
        v-model="user.password"
        :state="validateState('password')"
        placeholder="Senha"
        autocomplete="off"
      ></b-form-input>
      <b-form-invalid-feedback>
        <span v-if="!$v.user.password.minLength"
          >Password requer um mínimo de 6 caracteres.</span
        >
      </b-form-invalid-feedback>
      <b-form-text id="input-live-help" v-if="user.id"
        >Deixe em branco para não alterar.</b-form-text
      >
    </b-form-group>
    <b-form-group label="Confirmar Senha:">
      <b-form-input
        :type="'password'"
        v-model="user.password_confirmation"
        :state="validateState('password_confirmation')"
        placeholder="Confirmar senha"
      ></b-form-input>
      <b-form-invalid-feedback>
        <span v-if="!$v.user.password_confirmation.sameAsPassword"
          >Confirmação está diferente da senha.</span
        >
      </b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  email,
  sameAs,
  requiredIf,
} from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";
import { mask } from "vue-the-mask";

export default {
  components: {},
  mixins: [validationMixin],
  data: () => ({}),
  validations: {
    user: {
      name: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      password: {
        required: requiredIf(function (user) {
          return user.id ? false : true;
        }),
        minLength: minLength(6),
      },
      password_confirmation: {
        sameAsPassword: sameAs(function () {
          return this.user.password;
        }),
      },
    },
  },
  computed: {
    ...mapState("users", ["user"]),
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.user[name];
      return $dirty ? !$error : null;
    },
    isValid() {
      if (!this.user.password) {
        delete this.user.password;
      }

      if (!this.user.password_confirmation) {
        delete this.user.password_confirmation;
      }

      this.$v.user.$touch();
      return !this.$v.user.$anyError;
    },
  },
  async created() {
    this.user.password = "";
    this.user.password_confirmation = "";
  },
  directives: { mask },
};
</script>
