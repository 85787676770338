<template>
  <div>
    <b-form-group label="Roles:">
      <b-form-checkbox-group
        v-for="(role, key) in roles"
        :key="key"
        v-model="user.roles"
      >
        <b-form-checkbox :value="role.id">{{ role.name }}</b-form-checkbox>
      </b-form-checkbox-group>

      <b-form-invalid-feedback :state="validateState('roles')">
        <span v-if="!$v.user.roles.required">Role é um campo obrigatório.</span>
        <span v-if="!$v.user.roles.minLength"
          >Selecione pelo menos
          {{ $v.user.roles.$params.minLength.min }} papéis.</span
        >
      </b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  validations: {
    user: {
      roles: {
        required,
        minLength: minLength(1),
      },
    },
  },
  computed: {
    ...mapState("users", ["user", "roles"]),
  },
  methods: {
    ...mapActions("users", ["ActionSetRoles", "ActionGetAllRoles"]),
    validateState(name) {
      const { $dirty, $error } = this.$v.user[name];
      return $dirty ? !$error : null;
    },
    isValid() {
      this.$v.user.$touch();
      return !this.$v.user.$anyError;
    },
  },
  async created() {
    await this.ActionGetAllRoles({});
  },
};
</script>

<style></style>
