<template>
  <div class="w-100 mb-4">
    <Header />
    <FormVerify v-if="step == 1" @newUser="newUser" />
    <Form v-else @saved="loadData" />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Form from "../components/Form.vue";
import FormVerify from "../components/FormVerify.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: { Header, Form, FormVerify },
  data: () => ({
    step: 1
  }),
  computed: {
    ...mapState("users", ["user"])
  },
  methods: {
    ...mapActions("users", ["ActionNew", "ActionSetUser"]),
    loadData(id) {
      console.log("ID", id);
      //this.$router.push({ name: "user_edit", params: { id: id } });
    },
    newUser(user) {
      this.user.email = user.email;
      this.ActionSetUser(this.user);
      this.step = 2;
    }
  },
  async created() {
    this.ActionNew();
  }
};
</script>

<style lang="scss">
.vue-step-wizard {
  width: 100%;
}
</style>
