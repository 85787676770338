<template>
  <b-card no-body class="radius-top-0">
    <b-card-body>
      <h3>Verficação de usuário cadastrado</h3>
      <b-form-group v-if="!user">
        <b-input-group class="mt-3">
          <b-form-input
            v-model="email"
            placeholder="E-mail"
            :state="validateState('email')"
          ></b-form-input>

          <b-input-group-append>
            <b-button type="button" @click="verifyEmail" variant="primary"
              >Verificar</b-button
            >
          </b-input-group-append>
        </b-input-group>
        <b-form-invalid-feedback :state="validateState('email')">
          <span v-if="!$v.email.required">E-mail é um campo obrigatório.</span>
          <span v-if="!$v.email.email">E-mail inválido</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <div v-else-if="user && !user.id">
        <h5>Usuário não encontrado</h5>
        <p>Você deseja criar um novo usuário com o email {{ user.email }}</p>
        <div class="text-center">
          <b-button type="button" @click="criarUsuario" variant="primary"
            >Criar Usuário</b-button
          >
        </div>
      </div>
      <div v-else>
        <h5>Usuário encontrado</h5>
        <ul class="data-user">
          <li><span>Nome:</span> {{ user.name }}</li>
          <li><span>E-mail:</span> {{ user.email }}</li>
          <li><span>Criado:</span> {{ user.created_at | format_date }}</li>
          <li><span>Atualizado:</span> {{ user.updated_at | format_date }}</li>
        </ul>
        <div class="text-center">
          <b-button type="button" @click="relacionarUsuario" variant="primary"
            >Relacionar Usuário</b-button
          >
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapActions } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  mixins: [validationMixin],
  data: () => ({
    user: null,
    email: ""
  }),
  validations: {
    email: {
      required,
      email
    }
  },
  computed: {},
  methods: {
    ...mapActions("users", ["ActionVerify", "ActionJoin"]),
    async verifyEmail() {
      this.$v.$touch();
      if (!this.$v.$anyError) {
        try {
          this.user = await this.ActionVerify({ email: this.email });
        } catch (error) {
          this.user = {
            email: this.email
          };
        }
      }
    },
    async relacionarUsuario() {
      try {
        await this.ActionJoin(this.user.id);
        this.$notifications.addNotification({
          message: "Usuário relacionado com sucesso",
          type: "success"
        });

        this.$router.push({ name: "users_edit", params: { id: this.user.id } });
      } catch (error) {
        this.$notifications.addNotification({
          message: "Não foi possivel relacionar o usuário",
          type: "danger"
        });
      }
    },
    criarUsuario() {
      this.$v.$touch();
      if (!this.$v.$anyError) {
        console.log("this user", this.user);
        this.$emit("newUser", this.user);
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    }
  }
};
</script>

<style lang="scss" scoped>
.data-user {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    padding-top: 0.5em;
    span {
      font-weight: 500;
      width: 150px;
      display: block;
    }
  }
}
</style>
